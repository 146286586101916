<template>
	<div class="lineManagement">
		<jy-query ref="checkForm" :model="formInline">
			<jy-query-item label="场站编号:" prop="num">
				<el-input v-model="formInline.num" placeholder="请输入"></el-input>
			</jy-query-item>
			<jy-query-item label="场站名称:" prop="cName">
				<el-input v-model="formInline.cName" placeholder="请输入"></el-input>
			</jy-query-item>
			<jy-query-item label="场站类别:" prop="sType">
				<el-select v-model="formInline.sType" placeholder="请选择">
					<el-option v-for="item in lboptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</jy-query-item>
			<template slot="rightCol">
				<el-button type="primary" @click="oncheck" v-if="btnexist('scheduleBasicStationList')">查询</el-button>
				<el-button type="primary" @click="resetForm('checkForm')" v-if="btnexist('scheduleBasicStationReset')">重置</el-button>
				<!-- <el-button type="primary" @click="importFun" >导入</el-button> -->
				<el-row>
					<el-button type="primary" @click="add" v-if="btnexist('scheduleBasicStationInsert')">新增</el-button>
					<el-button type="primary" @click="batchdelete" v-if="btnexist('scheduleBasicStationBatchDelete')">批量删除</el-button>
					<!-- <el-button type="primary" @click="exportList" v-if="btnexist('export')">导出</el-button> -->
					<el-button type="primary" @click="importFun"  v-if="btnexist('scheduleBasicStationBatchImport')">导入</el-button>
				</el-row>
			</template>
		</jy-query>
		<jy-table ref="jyTable" :data="lists" @select-all="selectallchange" @select="selectchange">
			<jy-table-column type="index" width="60" label="序号" fixed="left"></jy-table-column>
			<jy-table-column type="selection" width="40" fixed="left"></jy-table-column>
			<jy-table-column prop="num" label="场站编号" min-width="120" fixed="left"></jy-table-column>
			<jy-table-column prop="cName" label="场站名称" min-width="150" fixed="left"></jy-table-column>
			<jy-table-column prop="alias" label="别名" min-width="120"></jy-table-column>
			<jy-table-column prop="orgNa" label="所属机构" min-width="120"></jy-table-column>
			<jy-table-column label="场站类别" min-width="100">
				<template slot-scope="scope">
                        {{scope.row.sType|sType}}
                    </template>
			</jy-table-column>
			<jy-table-column label="场站规模" min-width="100">
				<template slot-scope="scope">
                        {{scope.row.type|type}}
                    </template>
			</jy-table-column>
			<jy-table-column prop="routeN" label="线路" min-width="120"></jy-table-column>
			<jy-table-column prop="lng" label="经度" min-width="120"></jy-table-column>
			<jy-table-column prop="lat" label="纬度" min-width="120"></jy-table-column>
			<jy-table-column prop="bCity" label="所属城市" min-width="120"></jy-table-column>
			<jy-table-column prop="dAddr" label="详细位置" min-width="200"></jy-table-column>
			<jy-table-column prop="berths" label="泊位数" min-width="200"></jy-table-column>
			<jy-table-column prop="loArea" label="占地面积" min-width="200"></jy-table-column>
			<jy-table-column prop="buildArea" label="建筑面积" min-width="200"></jy-table-column>
			<jy-table-column prop="remark" label="备注" min-width="200"></jy-table-column>
			<jy-table-column prop="createT" label="创建日期" min-width="170"></jy-table-column>
			<jy-operate v-bind:list="operateList" width="160"></jy-operate>
		</jy-table>
		<jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
		<add-dialog ref="dialog" @updateList="getlist"></add-dialog>
		<jy-import ref="jyImport" :headers="uploadHeaders" :action="action" @downloadTemplate="downloadTemplate" @getElxData="getElxData" @confirm="confirm" :successLength="correctCount" :errList="listError" :status.sync="importStatus"></jy-import>
	</div>
</template>
<script>
	import adddialog from "@/components/pages/admin/basicInformation/depotManagement/adddepot";
    import {btnMixins} from '@/common/js/button.mixin'
	export default {
		data() {
			return {
				formInline: {
					num: "",
					cName: "",
					sType: "",
				},
				lists: [],
				routeList: [],
				lboptions: [
					{
						value: "",
						label: "全部",
					},
					{
						value: "0",
						label: "停车场站",
					},
					{
						value: "1",
						label: "维修场站",
					},
					{
						value: "2",
						label: "充电场站",
					},
					{
						value: "3",
						label: "加油场站",
					},
				],
				// 批量删除
				batchdeleteList: [],
				// 分页
				pageIndex: 1,
				total: 100,
				pageSize: 10,
				//  操作
				operateList: [],

				//导入
				uploadHeaders: {
					token: this.$util.getStor("token"),
				},
				action: "/IntelligentArrangeSystem/station/importSitesCach",
				importId: "",
				listError: [],
				correctCount: "",
				errorCount: "",

				importStatus: 1,

                btnMenuId:'scheduleBasicStation',
			};
		},
        mixins:[btnMixins],
		components: {
			"add-dialog": adddialog,
		},
		created() {
			this.getRouteList();
			this.getlist();
			this.setOperateList();
		},
		filters: {
			sType(val) {
                let v = ''
                switch (val) {
                    case '0':
                        v = '停车场站'
                        break
                    case '1':
                        v = '维修场站'
                        break
                    case '2':
                        v = '充电场站'
                        break
                    case '3':
                        v = '加油场站'
                        break
                }
                return v
			},
			type(val) {
				let v = ''
                switch (val) {
                    case '0':
                        v = '主场站'
                        break
                    case '1':
                        v = '副场站'
                        break
                }
                return v
			}
		},
		methods: {
			setOperateList() {
				let l = [
					{
						name: "修改",
						icon: "el-icon-edit-outline",
						fun: this.handleEdit,
                        isShow:()=>{
                            return this.btnexist('scheduleBasicStationUpdate')
                        },
					},
					{
						name: "删除",
						icon: "el-icon-delete",
						fun: this.handleDelete,
                        isShow:()=>{
                            return this.btnexist('scheduleBasicStationDelete')
                        },
					},
				];
				this.operateList = l;
			},
			getlist() {
				let url = "/station/queryPage";
				let option = {
					...this.formInline,
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
				};
				this.$http.post(url, option).then(({ detail }) => {
					this.lists = detail.list;
					this.lists.forEach((e) => {
						this.routeList.forEach((item) => {
							if(item.value == e.routeIds) {
								e.routeN = item.text
							}
						});
					});
					console.log(this.lists);
					this.total = detail.total;
				});
			},
			// 查询表格
			oncheck() {
				this.pageIndex = 1;
				this.getlist();
				console.log("更新");
			},
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.oncheck();
			},
			// 导出
			onexport() {
				console.log("导出");
			},
			// 新增
			add() {
				this.$refs.dialog.init();
				console.log("新增");
			},
			// 批量删除
			batchdelete() {
				if (!this.batchdeleteList.length) {
					this.$message({
						type: "warning",
						message: "请先选择删除项",
					});
					return false;
				}
				this.$confirm("是否删除选中场站?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						let option = {
                            stationIds:this.batchdeleteList.map(item=>item.stationId).join(',')
                        }
						this.$http.post("/station/batchDel", option).then((res) => {
                            this.$message({
                                message: res.msg,
                                type: "success",
                            });
                            this.getlist();
                        });
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
			},
			// 批量导入
			batchimport() {},

			selectchange(selection) {
				console.log(selection);
				this.batchdeleteList = selection;
			},
			selectallchange(selection) {
				console.log(selection);
				this.batchdeleteList = selection;
			},

			// 删除
			handleDelete(row) {
				this.$confirm("是否删除该场站?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						this.$http.post("/station/del", {stationId:row.stationId}).then((res) => {
                            this.$message({
                                message: res.msg,
                                type: "success",
                            });
                            this.getlist();
                        });
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
				//   console.log(row)
			},
			// 修改
			handleEdit(row) {
				this.$refs.dialog.init(row, "update");
				console.log("编辑");
			},
			// 分页
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getlist();
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.pageIndex = val;
				this.getlist();
			},

			//导出
			exportList() {
				let url = "/baseinfostation/exportStation";
				let option = {
					...this.formInline,
				};
				this.$http
					.post(url, option, { type: "format", isExcel: true })
					.then((res) => {
						this.$util.funDownload(res.data, "场站信息");
					});
			},
			//导入
			importFun() {
				this.$refs.jyImport.init();
			},
			downloadTemplate() {
				let url = "/station/downloadTemplate";
				this.$http
					.post(url, {}, { type: "format", isExcel: true })
					.then((res) => {
						this.$util.funDownload(res.data, "场站模板");
					});
			},
			getElxData(data) {
				console.log(data.detail);
				this.importId = data.detail.importId;
				let url = "/station/importStationCheck";
				let params = {
					importId: data.detail.importId,
				};
				this.$http.post(url, params).then((data) => {
					let { detail } = data;
					this.listError = detail.listError;
					this.correctCount = detail.correctCount;
					this.errorCount = detail.errorCount;
				});
			},
			confirm() {
				let params = {
					importId: this.importId,
				};
				let url = "/station/importStationData";
				this.$http.post(url, params).then(
					(data) => {
						if (data.code == 0) {
							this.importStatus = 1;
							this.oncheck();
						}
					},
					() => {
						this.importStatus = -1;
					}
				);
			},
			getRouteList() {
				let url = '/route/info/queryRouteInfoByOrgIds'
				this.$http.post(url).then(({ detail }) => {
					detail.forEach(one => {
						this.routeList.push({
						text: one.cName,
						value: one.routeId
					})
					})
				})
			},
		},
	};
</script>
<style lang="scss" scoped="brand">
</style>
<style lang="scss">
</style>
